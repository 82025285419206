<template>
  <ckeditor :value="editorData"
            @input="onEditorDataUpdate($event)"
            :editor="editor"
            :config="editorConfig"
            :label="label"
            spellcheck
            required
    />
</template>

<script>
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Placeholder from "@/plugins/messaging/ckeditor/Placeholder/Placeholder";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { htmlToText } from "html-to-text";

export default {
    name: "template_editor",
    props: {
        placeholders: {
            type: Array,
            required: true,
            default: [],
        },
        value: {
            type: String,
            required: true,
            default: '',
        },
        label: {
            type: String,
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [EssentialsPlugin, ParagraphPlugin, Placeholder],
                toolbar: {
                    items: ["undo", "redo", "placeholder"],
                },
                placeholderConfig: {
                    types: this.placeholders,
                },
            },
            editorData: this.convertRawTextToCkEditorWithPlaceholder(this.value),
            editorDataRaw: '',
        };
    },
    methods: {
        convertRawTextToCkEditorWithPlaceholder(text) {
            return text
                ? _.reduce(this.placeholders, (acc, el) => acc.replace(`${el.tagCode}`, `<span class="placeholder">${el.tagCode}</span>`), text)
                : '';
        },
        onEditorDataUpdate(value) {
            this.editorDataRaw = htmlToText(value);
            this.$emit('input', this.editorDataRaw);
        }
    },
    watch: {
        value(value) {
            if (value !== this.editorDataRaw) {
                this.editorData = this.convertRawTextToCkEditorWithPlaceholder(value);
            }
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/variables';
.ck.ck-editor__editable_inline.ck-focused {
    border-color: $brand-color !important;
}
</style>
