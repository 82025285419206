var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ckeditor", {
    attrs: {
      value: _vm.editorData,
      editor: _vm.editor,
      config: _vm.editorConfig,
      label: _vm.label,
      spellcheck: "",
      required: "",
    },
    on: {
      input: function ($event) {
        return _vm.onEditorDataUpdate($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }